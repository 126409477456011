
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const AboutUs = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Clubs and Association" mid="Student Corner" breadcrumbName="Clubs and Association" />
            <div className="inner-page">
                    <div className='container'>
                        <div className='row'>
                        <div className='col-lg-12'>
                        <h3 className="sub-title">Clubs and Association</h3>
                    </div>
                             
                            <div className='col-lg-12 col-md-12 animateMe'  data-animation="fadeInRight">
                                <p>There are various clubs and association active to instill a special love for different subjects. There are various pragrammes and activities held throughout the academic year. Student’s active participation under the guidance of the teachers helps to develop the personalities of the children, in all dimensions. </p>
                                <ul>
                                    <li>Science Club</li>
                                    <li>Nature Club</li>
                                    <li>Commerce club</li>
                                    <li>Language Club</li>
                                    <li>Health & wellness Club</li>
                                    <li>Sports Club</li>
                                    <li>Maths Club</li>
                                    <li>Career Club</li>
                                    <li>Charity Club</li>
                                    <li>Quiz Club</li>
                                    <li>F. M Club</li>
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}
export default AboutUs