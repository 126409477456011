import React, { useEffect, useState } from "react";
import { getGallery } from "../Service/Api";

function Gallery() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getGallery();
      console.log("get Gallery Data",topperData)
      setData(topperData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("events_data", data[0]);
  }, [data]);


  return (
    <>
      <div className="container-fluid animateMe" data-animation="fadeInUpBig">
          <div className="row">
            <div>
              <div className="col-lg-4 col-sm-6">
                <img
                  src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${data[0]?.attachments[0]}`}
                  className="img-fluid lg-mid"
                />
              </div>
              <div className="col-lg-4 col-sm-6">
                <img
                  src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${data[0]?.attachments[1]}`}
                  className="img-fluid lg-small"
                />
                <img
                  src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${data[0]?.attachments[2]}`}
                  className="img-fluid lg-small"
                />
              </div>
              <div className="col-lg-4">
                <img
                  src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${data[0]?.attachments[3]}`}
                  className="img-fluid lg-mid"
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="button">
                <a href="/gallery">VIEW ALL</a>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Gallery;
