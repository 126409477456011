import React, { useEffect, useState } from "react";
import { getVideo } from "../Service/Api";
import { Link } from 'react-router-dom' 
import Header from '../components/Header'
import Breadcrumb from '../components/Breadcrumb'
import Footer from "../components/Footer";
const VideoGallery = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All"); // Default to "All"
    const [yearRanges, setYearRanges] = useState([]);
    
  const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getVideo();
            setData(galleryData);

            // Extract unique years
            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear(); // Extract only the year
                        console.log(year)
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );

            // Remove the map function for generating year ranges
            const uniqueYearRanges = uniqueYears.map(year => {
                return `${year}`;
            });

            uniqueYearRanges.sort((a, b) => b - a);
            console.log("Unique Year Ranges:", uniqueYearRanges); // Log uniqueYearRanges
            setYearRanges(uniqueYearRanges);
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Filter data based on selected year
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date) {
                    const year = new Date(item.date).getFullYear();
                    return parseInt(selectedYear) === year;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
              item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
          }
        setFilteredData(filteredData);
    }, [selectedYear, searchQuery, data]);


    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Video Gallery" mid="Gallery" breadcrumbName="Video Gallery" />

            <div className="inner-page">
                <div className="container">
                    <div className="row tabs-dynamic">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                            <div className="chooseyear">
                            <select id="chooseddlYear" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="yearSelection">
                                <option value={"All"}>All</option>
                                {yearRanges.map((yr) => (
                                    <option key={yr} value={yr}>{yr}</option>
                                ))}
                            </select>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                            <div className="searchBox">
                                <input type="text" id="myInput" name="name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Here.." />
                            </div>
                        </div>
                    </div>
                    <div className="row"> 
                             {filterData.length > 0 ? (
                                filterData.map((item, index) => (
                            <div className="videodetails col-md-4" key={index}>

<div className="video-gallery">
                       <Link to={item.videoUrl} target="_blank">
                         <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} alt="St. Thomas School, Mainpuri UP" className="img-fluid mx-auto d-block"/>
                          <h3>{item.title}</h3>
                      </Link>                            
                   </div>

                                {/* <Link to={item.videoUrl} target="_blank">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/youtube.PNG" />
                                    <div className="videodesc">
                                        <h3>{item.title}</h3>
                                        <span><i class="bi bi-calendar2-week"></i> {item.date}</span>
                                    </div>
                                </Link> */}
                            </div>
                         ))
                         ) : (
                            <div className="videodetails col-md-4">

                            <div className="video-gallery">
                                                   <Link to="#" target="_blank">
                                                     <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/youtube.png" alt="St. Thomas School, Mainpuri UP" className="img-fluid mx-auto d-block"/>
                                                      <h3>Video Title</h3>
                                                  </Link>                            
                                               </div> 
                                                        </div>
                         )}
                        <div className="clr"></div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default VideoGallery