
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
import { useEffect, useState } from 'react';
import { getPrincipalMessage } from '../Service/Api';
const Principalmessage = () => {
    
    const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="Principal's Message" mid="About" breadcrumbName="Principal's Message" />
            <div className="inner-page">
                <div className='container'>
                {data && data.length > 0 ? (
            data.map((item, index) => (
                    <div className='row' key={index}>
                        <div className='col-lg-5 col-md-6 col-dm-12'>
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className='img-fluid' />
                        </div>
                        <div className='col-lg-7 col-md-6 col-dm-12'>
                        <div dangerouslySetInnerHTML={{ __html: item.message }} />
                            {/* <p><span>   <div dangerouslySetInnerHTML={{ __html: item.name }} /></span> </p>  */}
                        </div>
                    </div>
                     ))
                     ) : (<></>)
                   }
                    
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Principalmessage