import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
const ContactUs = () => {
    return(
        <>
        <Header/>
        <Breadcrumb pageTitle="Contact US" mid="Contact US" breadcrumbName="Contact US" />
        <div className="container">
            <div className="row">
              
                <div className="col-md-5">
                    <div> 
                         <h3 class="sub-title">Contact US</h3> 
                    </div>
                    <div>
                    <div class="cont-dtl">
                        <p><i class="bi bi-geo-alt"></i> Civil Line, Mainpuri,(U.P) - 205001 India</p>
                        <p><i class="bi bi-telephone-fill"></i> <a href="tel: +91-9557352271">+91-9557352271</a>, 
                        <a href="tel: +91-5672297121">+91-5672297121</a>, <a href="tel: +91-7536040116">+91-7536040116 <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/whatsapp.png" /></a></p>
                        <p><i class="bi bi-envelope"></i> <a href="mailto:stthomassrschool@gmail.com">stthomassrschool@gmail.com</a></p>
                        <p><i class="bi bi-globe"></i> <a href="http://www.stthomasmainpuri.com/"> www.stthomasmainpuri.com</a></p></div>
                        <p class="d-flex contact_Us"><a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/youtube.png" /></a><a href="https://www.instagram.com/st.thomasschool.mainpuri/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/contact-instagram.png" /></a><a href="https://twitter.com/stthomasschool_?lang=en" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/linkedin.png" /></a></p>
                    </div>
                </div>
                <div className="col-md-7">
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3547.301495403689!2d79.05823597545094!3d27.241072076452927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDE0JzI3LjkiTiA3OcKwMDMnMzguOSJF!5e0!3m2!1sen!2sin!4v1723609162725!5m2!1sen!2sin" width="100%" height="400px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
            </div>
        </div>
        <p></p>
        <Footer />
        </>
    )
}
export default ContactUs