// import logo from './logo.svg';
import './App.css';
import { BrowserRouter , Route,  Routes } from 'react-router-dom';

// import Home from './Pages/home'
import Home from './Pages/home';
import Laboratory from './Pages/laboratory';
import Patrons from './Pages/patrons';
import AboutUs from './Pages/about-us';
import Principalmessage from './Pages/principal-message';
import Activities from './Pages/activities';
import Timing from './Pages/timings';
import House from './Pages/house';
import ClubsAssociation from './Pages/clubs-association';
import Library from './Pages/library';
import ComputerLab from './Pages/computer-lab';
import Syllabus from './Pages/syllabus';
import PrimaryWing from './Pages/primary-wing';
import News from './Pages/news'
import Events from './Pages/Events'; 
import Gallery from './Pages/gallery';
import SubGallery from './Pages/SubGallery';
import VideoGallery from './Pages/VideoGallery';
import ErpSoftware from './Pages/erp-software';
import ParentPortal from './Pages/parent-portal';
import ContactUs from './Pages/contactUs';
import SchoolInformation from './Pages/school-information';
import AdmissionWithdrawal from './Pages/admission-withdrawal';

function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/laboratory" element={<Laboratory />} />
          <Route path="/patrons" element={<Patrons />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/principal-message" element={<Principalmessage />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/timings" element={<Timing />} />
          <Route path="/house" element={<House />} />
          <Route path="/clubs-association" element={<ClubsAssociation />} />
          <Route path="/library" element={<Library />} />
          <Route path="/computer-lab" element={<ComputerLab />} />
          <Route path="/syllabus" element={<Syllabus />} />
          <Route path="/primary-wing" element={<PrimaryWing />} />
          <Route path="/news" element={<News />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/SubGallery" element={<SubGallery />} />
          <Route path="/VideoGallery" element={<VideoGallery />} />
          <Route path="/erp-software" element={<ErpSoftware />} />
          <Route path="/parent-portal" element={<ParentPortal />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/school-information" element={<SchoolInformation />} />
          <Route path="/admission-withdrawal" element={<AdmissionWithdrawal />} />
        </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;