
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const Activities = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Activities"  breadcrumbName="Activities" />
            <div className="inner-page">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                            <p>In order to give a stage to test their competence in various fields, students are provided with ample opportunities to participate in sports and games and cultural events. To facilitate the same, children are taken to inter school competition and various competitions in the district level, state level and in national levels.</p>
                            <h3 className='sub-title sub-space'>SCHOOL PARLIAMENT</h3>
                            <p>To develop leadership qualities among the children, election to the school parliament is held every year. It is aimed at familiarizing children with the process of election, democratic values and knowledge of election procedures. The school captain and vice-captain are elected directly by the children through direct voting in the most democratic way.</p>
                            <h3 className='sub-title sub-space'>MORNING ASSEMBLY AND PRAYER</h3>
                            <p>Every day begins with assembly and prayer. This is compulsory for all the children. However physically challenged and sick may be exempted on request.</p>
                            <h3 className='sub-title sub-space'>ART AND CRAFT</h3>
                            <p>Through arts and craft, children learn to value and appreciate artifacts and images across cultures and times. Experience in design, art, and crafts enable them to reflect critically on their own work and those by others. They learn to act and think like designers and artists, working intelligently and creatively.</p>
                            <h3 className='sub-title sub-space'>CLUB AND ASSOCIATION</h3>
                            <p>To widen the scope and areas of co curricular activities, a number of Clubs and Association are formed and a number of programmes and activities are held under the auspices of Clubs and Associations viz. Science Club, Sports Club, Nature Club, Quiz and Cultural Association. As part of the activities of these Clubs, they organize Seminars, Talks, Experiments, Debates, Educational Tours and Quiz Competitions.</p>
                            <h3 className='sub-title sub-space'>ANNUAL EVENT</h3>
                            <p>Every year the school celebrates an event, in a grand manner as "Annual Event". It could be a Parent"s Day Celebration (A cultural bonanza) an exhibition (Art and Science) or a Sports day. (Athletic Event and PT Display).</p>
                            {/* <ul>
                                    <li>Students should arrive at the school at least five minutes before the warning bell for the assembly. The school is not responsible for goods lost, students are discouraged from bringing any expensive article to the school.</li>
                                    <li>Smoking and the use of drugs and alcohol are strictly forbidden in the school premises.</li>
                                    <li>Communal harmony is to be preserved and fostered.</li>
                                    <li>Strict regularity, implicit obedience, honesty, courtesy in speech and conduct, cleanliness of dress and person are expected from every pupil. Students and their parents must strictly confirm to the instructions, rules and regulation laid down in this regard.</li>
                                    <li>Pupils are responsible to the school authorities not only for their conduct in the school but also for their general behavior outside. Any report of objectionable conduct outside the school on the part of the pupils make them liable to disciplinary action.</li>
                                    <li>All the students should reach school at least 5 minutes before the warning bell for assembly. Late comers will not b admitted in the class without the sanction of the principal.</li>
                                    <li>All are expected to speak English in the school campus. No cell phones; literature such as newspapers, periodicals and the like shall be brought to school without the sanction of the principal.</li>
                                    <li>Irregularity in attendance, habitual idleness, disobedience, objectionable moral influence, frequent failure to obtain "Good" in conduct justify dismissal.</li>
                                    <li>Students are forbidden from using geared vehicles in the school premises.</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}
export default Activities