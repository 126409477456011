import React, { useEffect, useState } from "react"; 
import { getBirthdays } from "../Service/Api";

const Birthday = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const topperData = await getBirthdays();
                setData(topperData);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        fetchData();
    }, []);

    const emptyArray = [
        { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/cake.jpg" },   
        { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/cake.jpg" },   
        { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/cake.jpg" },   
        { name: "Student Name", class: "Class", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/cake.jpg" },   
    ];

    return(
        <>
            <marquee scrollamount="2" scrolldelay="30" direction="up" align="left" onmouseover="stop();" onmouseout="start();" >       
                {data.length > 0 ? data.map((item, index) => (
                    <div className="birthday-blk" key={index}>
                        <div className="img">
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} alt="Birthday" />
                        </div>
                        <div className="content">
                            <h3>{item.name}</h3> 
                            <p className="primary">{item.class}</p>
                        </div>
                    </div>
                )) : emptyArray.map((item, index) => (
                    <div className="birthday-blk" key={index}>
                        <div className="img">
                            <img src={item.attachments} alt="Placeholder" />
                        </div>
                        <div className="content">
                            <h3>{item.name}</h3> 
                            <p className="primary">{item.class}</p>
                        </div>
                    </div>
                ))}
            </marquee>
        </>
    );
};

export default Birthday;
