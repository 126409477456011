
import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
const PrimaryWing = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Primary Wing" mid="Student Corner" breadcrumbName="Primary Wing" />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Primary Wing</h3>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                        <h3>Activities of Nursery, LKG & UKG (2024 - 25)</h3>
                        <div className='table-responsive'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>April</td>
                                        <td>Colouring / Drawing Competition</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="4">July</td>
                                        <td>English & Hindi Rhymes       </td>
                                    </tr>
                                    <tr>
                                        <td>Solo Dance</td>
                                    </tr>
                                    <tr>
                                        <td>English & Hindi Story telling competition</td>
                                    </tr>
                                    <tr>
                                        <td>English & Hindi Recitation</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="2">August</td>
                                        <td>Rakhi Making</td>
                                    </tr>
                                    <tr>
                                        <td>Fancy Dress</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="3">October</td>
                                        <td>English & Hindi Speech competition </td>
                                    </tr>
                                    <tr>
                                        <td>GK & Memory Test</td>
                                    </tr>
                                    <tr>
                                        <td>Diya Decoration</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="3">November</td>
                                        <td>English & Hindi Handwriting</td>
                                    </tr>
                                    <tr>
                                        <td>Sports Day</td>
                                    </tr>
                                    <tr>
                                        <td>King & Queen Competition</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">SPORTS</td>
                                    </tr>
                                    <tr>
                                        <td>April</td>
                                        <td>In and Out</td>
                                    </tr>
                                    <tr>
                                        <td>July</td>
                                        <td>Get Ready to School</td>
                                    </tr>
                                    <tr>
                                        <td>August</td>
                                        <td>Frog Race</td>
                                    </tr>
                                    <tr>
                                        <td>October</td>
                                        <td>Running Race 50 m</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-xs-12'>
                        <h3>Activities of Class I to IV (2024 - 25)</h3>
                    <div className='table-responsive'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan="2">April</td>
                                        <td>English & Hindi Handwriting</td>
                                    </tr>
                                    <tr>
                                        
                                        <td>Drawing Competition</td>
                                    </tr>




                                    
                                    <tr>
                                        <td rowSpan="3">July</td>
                                        <td>English / Hindi Rhymes & Story telling</td>
                                    </tr>
                                    <tr>
                                        <td>Solo Dance Competition</td>
                                    </tr>
                                    <tr>
                                        <td>Hindi & English Solo Song competition </td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="4">August</td>
                                        <td>Group Song Competition</td>
                                    </tr>
                                    <tr>
                                        <td>Rakhi Making</td>
                                    </tr>
                                    <tr>
                                        <td>Fancy Dress Competition</td>
                                    </tr>
                                    <tr>
                                        <td>Rangoli Competition</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="3">October</td>
                                        <td>English & Hindi Speech competition </td>
                                    </tr>
                                    <tr>
                                        <td>Diya Decoration</td>
                                    </tr>
                                    <tr>
                                        <td>Group Dance competition</td>
                                    </tr>
                                    <tr>
                                        <td>November</td>
                                        <td>Sports Day</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">SPORTS - I & II</td> 
                                    </tr>
                                    <tr>
                                        <td>April</td>
                                        <td>Passing the ball</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="2">July</td>
                                        <td>Musical Chair</td>
                                    </tr>
                                    <tr> 
                                        <td>Long Jump</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan="2">August</td>
                                        <td>Frog Race</td>
                                    </tr>
                                    <tr> 
                                        <td>Running Race 100 m</td>
                                    </tr>
                                    <tr>
                                        <td>October</td>
                                        <td>Lemon & Spoon</td>
                                    </tr> 


                                    <tr>
                                        <td colspan="2">SPORTS - III & IV</td> 
                                    </tr>
                                    <tr>
                                        <td>April</td>
                                        <td>Sack Race</td>
                                    </tr>
                                    <tr>
                                        <td>July</td>
                                        <td>Long Jump</td>
                                    </tr> 
                                    <tr>
                                        <td>August</td>
                                        <td>Running Race 100 m</td>
                                    </tr>
                                    
                                    <tr>
                                        <td rowSpan="2">October</td>
                                        <td>Kho Kho for Girls</td>
                                    </tr> <tr> 
                                        <td>Cricket for Boys </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                 
            </div>
            <Footer />
        </>
    )
}
export default PrimaryWing