
import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
const ComputerLab = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Computer Lab" mid="Facilities" breadcrumbName="Computer Lab" />
            <div className='container facilities_img'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className="sub-title">Computer Lab</h3>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer1.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer1.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer2.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer2.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer3.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer3.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer4.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer4.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer5.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer5.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>

                    
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer6.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer6.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer7.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer7.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer8.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer8.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer9.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer9.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12'>
                        <div className='inner-img'>
                        <a data-magnify="gallery" data-src="" data-group="a" href='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer10.jpg'>
                            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/computer10.jpg' className='img-fluid' />
                            </a>
                        </div>
                    </div>

                     

                </div>
                 
            </div>
            <Footer />
        </>
    )
}
export default ComputerLab