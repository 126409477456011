import React, { useEffect, useState } from "react"; 
import { getNews } from "../Service/Api";

const News = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      const getData = async () => {
        try {
          const datas = await getNews();
          setData(datas);
        } catch (error) {
          console.error("Failed to fetch news:", error);
          setData([]); // Set to empty to ensure the empty state is handled
        }
      };
      getData();
    }, []);

    return (
        <>
            <marquee scrollamount="2" scrolldelay="30" direction="up" align="left" onmouseover="stop();" onmouseout="start();">
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <div className='newsarea' key={index}>
                            <p className='newshead'>{item.description}
                                {item.attachments && item.attachments[0] && (
                                    <a href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target='_blank' rel='noopener noreferrer'>
                                        <i className="bi bi-paperclip"></i>
                                    </a>
                                )}
                            </p>
                        </div>
                    ))
                ) : (
                    <div className="news-area">
                        <p className="news-head">No current news updates available.</p>
                    </div>
                )}
            </marquee>
        </>
    );
};

export default News;
