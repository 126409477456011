import React from "react";
import { Link } from 'react-router-dom';

const Footer = () => {
    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/Logo.png" />
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to='/parent-portal'>Portal Help?</Link></li>
                                    <li><Link to='/erp-software'>ERP Software</Link></li>
                                    <li><a href="">Sitemap</a></li>
                                    <li><a href="">Careers</a></li>
                                    <li><a href="">Alumni</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6">
                            <div className="cont-dtl">
                                <h3>Location</h3>
                                <p><i class="bi bi-geo-alt"></i> Civil Line, Mainpuri,(U.P) - 205001 India</p>
                                <p><i class="bi bi-telephone-fill"></i> <a href="tel: +91-9557352271">+91-9557352271</a>, 
                        <a href="tel: +91-5672297121">+91-5672297121</a>, <br></br><a href="tel: +91-7536040116">+91-7536040116 <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/whatsapp.png" /></a></p>
                                <p><i class="bi bi-envelope"></i> <a href="mailto:stthomassrschool@gmail.com">stthomassrschool@gmail.com</a></p>
                                <p><i class="bi bi-globe"></i> <a href="http://www.stthomasmainpuri.com/">www.stthomasmainpuri.com</a></p>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3547.301495403689!2d79.05823597545094!3d27.241072076452927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDE0JzI3LjkiTiA3OcKwMDMnMzguOSJF!5e0!3m2!1sen!2sin!4v1723609162725!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="d-flex">
                                <div className="app-cont">
                                    <p>Download mobile App</p>
                                    <div>
                                    <a href='https://apps.apple.com/in/app/campuscare-10x/id1611283814' target='_blank'> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/app1.png" /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN' target='_blank'> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/app2.png" /></a>
                                    </div>
                                    <p className="link">
                                        <a hre="stthomasmainpuri.com">Use School Code :  STSMUP</a>
                                    </p>
                                </div>
                                <div className="phone-img">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/app3.png" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/footer-img.png" className="img-fluid" />  
            </footer>
        </>
    )
}
export default Footer;