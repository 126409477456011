
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const ErpSoftware = () => {
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="ERP Software" mid="ERP Software" breadcrumbName="ERP Software" />
            <div className="inner-page erp_sec">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 animateMe' data-animation="fadeInLeft">
                            <div className="erp_sec">
                                <div className="col-xs-12 col-sm-12 col-md-9">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <p className="details">Entab Infotech Pvt Ltd., a company formed in 2000 and serving the school with automation technologies has evolved out as the best service provider through their advanced <a href="https://www.entab.in/school-management-erp.aspx"> <b>ERP Software:</b></a> 'CampusCare®' and communication tools. We are also proud to be associated with them for managing the data of students, exam, fees, staff and  their accounts etc in addition to the <a href="https://www.entab.in/school-communication-portals.aspx"> <b> communication portal</b></a> and <a href="https://www.entab.in/mobile-apps.aspx"> <b> mobile apps</b></a>.
                                            <br />The entire system is strengthening the academic of the students and preparing responsible staff and students. Together the reputation of the school grows higher. We value the relation with  <a href="https://www.entab.in/"> <b>Entab: CampusCare®</b></a> and look forward to receive the much more in technological front.</p>
                                        </div>
                                    </div>
                                    <div className="clr25"></div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-4 col-md-4">
                                            <h4 className="imgheading"><a href="https://www.entab.in/school-management-erp.aspx"> Manage Data</a></h4>
                                            <img id="myImg" src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/Manage-Data.gif" alt="School Management System, Management ERP Software" />
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-md-4">
                                            <h4 className="imgheading"><a href="https://www.entab.in/school-communication-portals.aspx#PPC"> Parent Portal</a></h4>


                                            <img id="myImg2"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/Parent-Portal.gif" alt="st thomas school" />
                                        </div>
                                        <div className="col-xs-12 col-sm-4 col-md-4">
                                            <h4 className="imgheading"><a href="https://www.entab.in/school-communication-portals.aspx#PPC"> Communication Portal</a></h4>
                                            <img id="myImg2"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/communication.gif" alt="st thomas school" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-3">
                                    <h4 className="imgheading"><a href="https://www.entab.in/mobile-apps.aspx">CampusCare</a></h4>
                                    <center><a href="https://www.entab.in/mobile-apps.aspx">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/Mobile-slides.gif" alt="School ERP Software, Mobile App, School Management Software, Software for School" /></a> </center>
                                </div>
                                <div className="clr"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default ErpSoftware