// import react { useState } from 'react';
import { useState } from 'react'; 
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const House = () => {
    const [selectedTab, setSelectedTab] = useState('tab1');
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };
    return (
        <>
            <Header />
            <Breadcrumb pageTitle="School Houses" mid="Student Corner" breadcrumbName="School Houses" />
            <div className="inner-page">
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-12 col-md-12 animateMe'>
                            <p>For effective management and discipline of students and create and foster 'a spirit of co-operation' among them to develop deep love and devotion towards their Alma mater, a house system has developed in the school. Thus, the students are divided into four houses distinguished by four colours Blue, Green, Yellow and Red. A healthy competition is encouraged among them to achieve the standard of excellence and to develop higher qualities of leadership.</p>

                        </div>
                        <div className='col-lg-4 col-md-5 col-sm-12 house-block'>
                            <div className="tabs house-tab">
                                <button className={selectedTab === 'tab1' ? 'active' : ''}  onClick={() => handleTabClick('tab1')}>          ST. MARY'S HOUSE (BLUE)        </button>
                                <button className={selectedTab === 'tab2' ? 'active' : ''} onClick={() => handleTabClick('tab2')}>       ST. ANTONY'S HOUSE (YELLOW)    </button>
                                <button className={selectedTab === 'tab3' ? 'active' : ''} onClick={() => handleTabClick('tab3')}>      ST. JOSEPH HOUSE (GREEN)      </button>
                                <button className={selectedTab === 'tab4' ? 'active' : ''} onClick={() => handleTabClick('tab4')}>     MOTHER TERESA'S HOUSE (RED)  </button>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-7 col-sm-12 house-cont house-block'>
                            <div className="tab-content">
                                {selectedTab === 'tab1' && <div className='bluehouse'>
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/blue-house.jpg" alt="St. Thomas School, Mainpuri" />
                                    <h3 className='sub-title'>ST. MARY'S HOUSE (BLUE)</h3>
                                    <p>St. Mary's house has Mother Mary as the patron saint. She was the earthly mother of Jesus. She was a willing servant who trusted God and obeyed HIS will. The strong belief in God and obedient to them, is the significant character of Mother Mary.</p>
                                    <p>The house has 'Blue' as its colour. Blue is the colour of the sky and sea. It is associated with depth and stability. It symbolizes trust, loyalty, wisdom, confidence, faith, truth and heaven. While members compete in the events, these virtues may become their guiding principles.</p>
                                    <p>Getting inspired by the life of mother Mary, the house accepted charity as its motto. The members of the house ought to assimilate and cultivate the charity for others by which the love for his/her brotheren will flourish in them.</p>
                                </div>
                                }
                                {selectedTab === 'tab2' && <div className='yellowhouse'>
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/yellow-house.jpg" alt="St. Thomas School, Mainpuri" />
                                <h3 className='sub-title'>ST. ANTONY'S HOUSE (YELLOW)</h3>
                                    <p>The Yellow house has got St. Antony as the patron saint. He was a devoted Christian priest of Franciscan order who dedicated his whole life for Jesus. He is known as the patron saint of the poor because he kept a special love for poor and he practiced the virtue of poverty in his own life. He was always true to his life as a Christian priest.</p>
                                    <p>the colour of the sun. The colour stands for hope and positivity. It is known as the most attractive colour. It advices the members to be the people of hope and positivity and make our life attractive like the yellow colour.</p>
                                    <p>The motto of the house is 'Truth'. The emblem says 'the lamp of righteousness should never be put off. Truth is virtue of virtues. The members are expected to practice this virtue and always stand firmly for the truth as St. Antony did in his life.</p>
                                </div>}
                                {selectedTab === 'tab3' && <div className='greenhouse'>
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/green-house.jpg" alt="St. Thomas School, Mainpuri" />
                                <h3  className='sub-title'>ST. JOSEPH HOUSE (GREEN)</h3>
                                    <p>The St. Joseph house is patronized by St. Joseph. He is the father of Jesus on earth. He was the man of simplicity. He trusted God above all, and subjected himself to God's pleasure. His life is a true inspiration for us to lead a life of simplicity above all our achievements.</p>
                                    <p>The house is given Green as its colour. Green is the colour of the nature. It stands for life. Green represents tranquillity, good luck and health. Being the colour of the nature, it inspires us to lead our life most beautifully with its true value.</p>
                                    <p>Co-operation is the motto of the house. As a team, it is impossible to get succeeded without unity and co-operation. The emblem of the house reinstates the virtue of co-operation and says 'talent wins games, teamwork wins the championship'.</p>
                                </div>}
                                {selectedTab === 'tab4' && <div className='redhouse'>
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STHSM/public/Images/red-house.jpg" alt="St. Thomas School, Mainpuri" />
                                <h3  className='sub-title'>MOTHER TERESA'S HOUSE (RED)</h3>
                                    <p>The house is named after Mother Teresa who is the patron saint of Red house. Mother Teresa, a Christian nun who dedicated her life for the sake of Jesus is known as the saint Teresa of Calcutta.</p>
                                    <p>She led a selfless life in the service of the people who were abandoned by all. It is always a matter of pride for each Indian that she lived and died as an Indian.</p>
                                    <p>Red colour is standing for mother Teresa's house. Red, being the colour of blood, represents the energy, life and courage. It is the courage that makes one to compete with the other. It says to its members not to lose heart but always stand bold.</p>
                                    <p>'Service' is the motto, of the house members. The patron saint is the best example for them to be at the service of others. Members need to learn how to be gentle with others and develop the unity which will lead them to be the champions.</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default House