import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from "../components/Breadcrumb";
const SchoolInformation = () => {
    return(
        <>
        <Header/>
        <Breadcrumb pageTitle="School Information" mid="About" breadcrumbName="School Information" />
        <div className="container">
            <div className="row">
              
                 <div className='col-md-12'>
                 <h3 class="sub-title">School Information</h3> 
                    <div className='table-responsive'>
                        <table>
                             <tr>
                                <td>Name of the school With Address</td>
                                <td>St. Thomas School Civil Line, Mainpuri, Uttar Pradesh - 205001</td>
                             </tr>
                             <tr>
                                <td>E-mail</td>
                                <td><a href="mailto: stthomassrschool@gmail.com">stthomassrschool@gmail.com</a></td>
                             </tr>
                             <tr>
                                <td>Phone Number</td>
                                <td><a href="tel: 5672297121">5672297121</a>, <a href="tel: 9557352271">9557352271</a>, <a href="tel: 7536040116">7536040116(whatsApp)</a></td>
                             </tr>
                             {/* <tr>
                                <td>Fax No</td>
                                <td>-</td>
                             </tr> */}
                             <tr>
                                <td>Year of Establishment of School</td>
                                <td>1976 </td>
                             </tr>
                             <tr>
                                <td>Board Affiliation</td>
                                <td>CISCE, New Delhi </td>
                             </tr>
                             <tr>
                                <td>Affiliation No.</td>
                                <td>UP- 168</td>
                             </tr>
                             <tr>
                                <td>Affiliation with the Board since</td>
                                <td>2003</td>
                             </tr>
                             <tr>
                                <td>Name of Trust/Society/Company</td>
                                <td>Christu Raj Education Society</td>
                             </tr>
                             <tr>
                                <td>E-mail</td>
                                <td><a href="mailto:stthomassrschool@gmail.com">stthomassrschool@gmail.com</a></td>
                             </tr>
                        </table>
                    </div>
                    
                    <div className='table-responsive'>
                        <table>
                            <thead>
                                <th colSpan="2">Name and official address of the principal</th>
                            </thead>
                            <tbody>
                             <tr>
                                <td>Name</td>
                                <td>Rev. Fr. Binoy Joseph</td>
                             </tr>
                             <tr>
                                <td>Address</td>
                                <td>St. Thomas School Civil Line, Mainpuri, Uttar Pradesh - 205001</td>
                             </tr>
                             <tr>
                                <td>E-mail</td>
                                <td><a href='mailto: principalstthomasmainpuri@gmail.com'>principalstthomasmainpuri@gmail.com</a></td>
                             </tr>
                             <tr>
                                <td>Phone number</td>
                                <td><a href="tel: 7251021066">7251021066</a></td>
                             </tr> 
                             </tbody>
                        </table>
                    </div> 
                    <div className='table-responsive'>
                        <table>
                        <thead>
                                <th colSpan="2">Other Details</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Academic session period</td>
                                <td>April to March</td>
                            </tr>
                            <tr>
                                <td>Vacation period</td>
                                <td>Mid May to June end</td>
                            </tr>
                           
                            </tbody>
                        </table>
                    </div> 
                  
                 </div>
            </div>
        </div>
        <p></p>
        <Footer />
        </>
    )
}
export default SchoolInformation