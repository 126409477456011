
import Header from '../components/Header'
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer';
const AdmissionWithdrawal = () => {
    return(
        <>
            <Header/>
            <Breadcrumb pageTitle="Admission and Withdrawal" mid="Admission" breadcrumbName="Admission and Withdrawal" />
            <div className="inner-page">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                            <h3 className="sub-title">Admission and Withdrawal</h3>
                            </div>
                            
                            <div className='col-lg-12 col-md-12 animateMe'  data-animation="fadeInRight">
                                <p>Admission for Nursery class is usually in February/March. Guardians seeking admission of their wards are required to fill in prescribed Registration form. </p>
                                <p>The child must have <b>completed 3 years by March</b>. Two attested copies of Birth Certificate from the Municipal Board or Gram Panchayat secretary should be submitted at the time of registration. Name and the date of birth once entered will not be changed later. Students seeking admission to any other class are to appear for an entrance test on a date fixed by the school, parents are asked to fill in the admission form with utmost accuracy. The date of birth once entered will not be changed later.</p>
                                <p>A student seeking admission to any class will be eligible for admission to that class only if he/she :-</p>
                                <ul>
                                    <li>has been studying in a school recognised by or affiliated to CISCE, Delhi or any other recognised Board of Secondary education in India.</li>
                                    <li>has passed qualifying examination making him eligible for admission to that class.</li>
                                    <li>Satisfies the requirement of age limits (minimum and maximum) as determined by the State/U.T. Government and applicable to the place the school is located.</li>
                                    <li>Produces:

                                        <ul>
                                            <li>The school Leaving Certificate/ Transfer Certificate signed by the head of the institution last attended and counter signed; and</li>
                                            <li>Documents in support of his having passed of then qualifying or equivalent qualifying examination.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>New admissions are taken against vacant seats only.</p>
                                <p>No leaving certificate will be issued until all dues to school have been paid.</p>
                                <p>The school reserves to itself the right to dismiss students whose academic performance is steadily unsatisfactory or whose conduct is harmful to other students. Grave insubordination or contempt of authority justifies immediate dismissal.</p>
                                <h3 >Admission To Class XI</h3>
                                <ul>
                                    <li>Since admission of Class XI is designated to be selective in the board's Scheme of education students of Class X must apply for admission to Class XI after their 10th standard. Forms for admission to class XI must be collected from the school office. Dates will be announced to the children. If forms are not collected on the prescribed dates it will be presumed that your child will not be coming back for admission to the +2 classes. The completed forms are to be returned to the office on the prescribed dates.</li>
                                    <li>Entry to the plus '2' course (CISCE) is by selection and choice. Students are selected to class XI on the basis of their conduct and consistently good academic record in the previous classes. Thus entry to standard XI will be regulated by MERITS. Merit is calculated basing on marks obtained in the CISCE Exam, as well as the record of the student's GENERAL PERFORMANCE AND BEHAVIOUR while he/she was in the school i.e. regularity, punctuality, proficiency in studies as seen in the home examination, extra and co-curricular activities will be taken into account.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}
export default AdmissionWithdrawal